@import url('https://fonts.googleapis.com/css?family=Montserrat');

.mySlides {display: none}

.slideshow{
    background-color: #97aa8f;
    padding-bottom: 25px;
    border-top: #997330 solid 5px;
    border-bottom: #997330 solid 5px;
    border-top-right-radius: 150px;
    border-bottom-left-radius: 150px;
}

/* Slideshow container */
.slideshow-container {
    width: 100%;
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
}

.mySlides{
    width: 70%;
    display:flex;
    justify-content: center;
    flex-direction: column;
    padding: 120px 0 100px 0;
}

/* Next & previous buttons */
.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
}

/* The dots/bullets/indicators */
.dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active, .dot:hover {
    background-color: #717171;
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

.slideshow-text{
    font-size: 16px;
    font-family: Montserrat;
    color: #fff;
    text-align: center;
}

@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
    .prev, .next,.text {font-size: 11px}
}