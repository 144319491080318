.about-image{
    width: 80%;
}

.about-title{
    margin-top: 50px;
    margin-bottom: 50px;
}

.about-title{
    font-weight: unset;
    text-align: left;
    text-decoration: underline 2px;
}

.about-text {
    font-size: 16px;
    margin-bottom: 20px;
}

.about-button:hover{
    color: #997330;
    box-shadow: 5px 5px #455621;
    font-weight: bold;
}
.menuc {
    display: flex;
    flex-direction: row;
}

.menu-leftc {
    width: 40%;
    margin-right: 5%;
    margin-left: 10%;
    align-self: center;
}

.menu-rightc {
    width: 60%;
}


@media (max-width: 1200px) {
    .menuc {
        flex-direction: column-reverse;
    }

    .menu-leftc {
        width: 80%;
        margin-right: 10%;
        margin-left: 10%;
    }

    .menu-rightc {
        width: 80%;
        margin: 0 10%;
    }
    .about-button{
        width: 60%;
        font-size: 3.5vw;
    }
    .about{
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .about-image {
        width: 100%;
        margin-bottom: 50px;
    }
}

