.menu{
    display: flex;
    flex-direction: row;
}

.menu-title{
    text-decoration: underline;
}

.menu-left{
    width: 35%;
    margin-right: 5%;
}
.menu-right{
    width: 60%;
}

.menu-left-2{
    width: 60%;
    margin-right: 5%;
}
.menu-right-2{
    width: 35%;
}

.menu-box{
    background-color: #FFFFFF;
    border: #97aa8f 2px solid;
    padding-bottom: 15px;
    margin-bottom: 50px;
}

.menu-list{
    width: 90%;
    padding: 20px 5px 0 15px;
    display: block;
}

.gerecht{
    color: #97aa8f;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 15px;
}

.gerecht2{
    color: #97aa8f;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;

}

.omschrijving{
    font-size: 14px;
}

.menu-revert{
    display: flex;
    flex-direction: row-reverse;
}

.wjd-title{
    margin-top: -20px;
    margin-bottom: 10px;
    font-size: 24px;
    color: #455621;
    font-family: "Times New Roman";
}

.wjd-tekst{
    color: #455621;
    font-size: 14px;
}

@media (max-width: 1200px) {
    .menu{
        flex-direction: column;
    }
    .menu-left, .menu-right, .menu-left-2, .menu-right-2{
        width: 90%;
        margin: 0 5%;
    }
    .bottom-images{
        flex-direction: column;
        align-items: center;
    }
    .bottom-images>img{
        width: 90%;
        margin-bottom: 50px;
    }
}