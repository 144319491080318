@import url('https://fonts.googleapis.com/css?family=Montserrat');

*{
    margin: 0px;
    padding: 0px;
}
body{
    background-color: #e5d8c7!important;
}

.closed{
    visibility: unset;
    z-index: 1;
    position: fixed;
    width: 40%;
    background: darkgray;
    opacity: 90%;
    border: black 5px solid;
    text-align: center;
    border-radius: 25px;
    padding: 30px 20px;
    top: 30%;
    left: 29%;
}

.closed > p{
    font-size: .85rem;
}

.main{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 15%;
}

.top-space{
    height: 200px;
}

.space{
    height: 75px;
}

h1, h6{
    color: #997330;
    align-self: center;
    font-family: hello paris;
    font-weight: bold;
    font-size: 3vw;
    text-align: center;
}

.h1-image{
    width: 60%;
    margin: 15px 20% 0 20%
}

h2{
    color: #997330;
    align-self: center;
}


h3{
    color: #997330;
    align-self: center;
    text-align: center;
    font-family: hello paris;
    font-weight: bold;
    font-size: 2.5vw;
    text-decoration: underline;
}

h4{
    color: #FFF;
    align-self: center;
    font-family: hello paris;
    font-size: 3vw;
    margin-bottom: 25px;
}

.content-box{
    display: flex;
    flex-direction: row;
}

.content-img{
    width: 55%;
}

.content-text{
    width: 45%;
    padding-left: 5%;
}

.content-text>p, p{
    font-size: 20px;
    font-family: Montserrat;
}

.button{
    margin: 40px 5%;
    justify-content: space-around;
    background-color: #97aa8f;
    width: 80%;
    padding: 10px;
    display: flex;
    font-size: 2vw;
    font-family: hello paris;
    color: #FFF;
    text-decoration: none;
    border: #997330 solid 3px;
    border-radius: 5px;
}

.button:hover{
    color: #997330;
    box-shadow: 5px 5px #455621;
    font-weight: bold;
}

.button2{
    margin: 60px 0 30px 0;
    justify-content: space-around;
    background-color: #997330;
    width: 40%;
    padding: 10px;
    display: flex;
    font-size: 2.0vw;
    font-family: hello paris;
    color: #FFF;
    text-decoration: none;
    border: #455621 solid 3px;
    border-radius: 5px;
}

.button2:hover{
    color: #455621;
    font-weight: bold;
    box-shadow: 5px 5px #e5d8c7;
}



.specials{
    padding: 50px 0;
    width: 80%;
    margin: 0 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFF;
    align-items: center;
    box-shadow: 1px 1px 5px 1px #000
}

.specials-text-title{
    font-size: 18px;
    font-family: Montserrat;
    font-weight: bold;
    color: #97aa8f;
    margin-top: 50px;
    text-align: center;
}

.specials-text{
    font-size: 16px;
    font-family: Montserrat;
    color: #000;
    text-align: center;
}

.space2{
    margin-bottom: 25px;
}

.bottom-images{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bottom-images>img{
    width: 30%;
}

@media (max-width: 1250px) {
    .content-box{
        flex-direction: column;
        align-items: center;
    }
    .content-img {
        width: 90%;
    }
    .content-text {
        width: 90%;
        padding-left: 0%;
        margin-top: 50px;
    }
    .button{
        width: 60%;
        margin: 5% 20%;
        font-size: 2.4vw;
    }
    .button2{
        font-size: 2.4vw;
    }
    .specials{
        width: 100%;
        margin: 0;
    }
    h1, h6{
        font-size: 3.5vw;
    }
    h3{
        font-size: 3vw;
    }
}

@media (max-width: 1000px) {
    .button{
        font-size: 4vw;
        width: 80%;
        margin: 5% 10%;
    }
    .button2{
        font-size: 3.5vw;
        width: 60%;
    }
    h1, h6{
        font-size: 4.5vw;
    }
    h3{
        font-size: 4vw;
    }
    h4{
        font-size: 5vw;
    }
    .h1-image{
        width: 80%;
        margin: 15px 10% 0 10%
    }

}

@media (max-width: 600px) {
    .closed{
        left: 25%;
    }
}



