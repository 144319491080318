footer{
    background-color: #282725;
    padding: 50px 0
}

.main-footer{
    display: flex;
    color: #fff;
    font-size: 16px;
    font-family: Montserrat;
    justify-content: space-between;
}

.footer-title{
    color: #997330;
    font-size: 24px;
    text-decoration: underline;
    font-family: Montserrat;
}

.main-f-c{
    width: 30%;
}

.bottom-footer{
    background-color: #997330;
    padding: 15px;
}

@media (max-width: 1000px) {
    .main-f-c {
        width: 100%;
        margin-bottom: 25px;
    }
    .main-footer {
        flex-direction: column;
    }
}