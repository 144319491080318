.top-header{
    background-color: #997330;
    padding: 0 10%
}

.top-header-span, .top-header-span>a{
    color: #FFFFFF;
    text-decoration: none;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: bold;
}

.phone{
    padding: 5px 5px 0 0;
    width: 12px;
    height: 18px;
}

header{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    border-bottom: solid 3px #997330
}

.main-header{
    background-color: #97aa8f;
}

.main-header>nav>ul>li{
    float: left;
}

.header-image-div{
    width: 10%
}
ul{
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 40%;
    align-items: center;
    justify-content: space-around;
}


.width-div{
    width: 90%;
    margin: 0 5%;
    padding: 5px;
    display: flex;
}

nav{
    display:flex;
    justify-content: center;
    padding: 10px 0
}

nav>ul>li>a{
    text-decoration: none;
    font-size: 26px;
    color: #FFF;
    font-family: hello paris;
    font-weight: bold;
}

nav>ul>li>a:hover{
    color: #997330;
}

@media (max-width: 1000px) {
    nav>ul>li>a {
        font-size: 4vw;
    }
    ul{
        width: 80%
    }
    nav>ul>li>a>img{
        width: 100px;
    }

    .top-header-span, .top-header-span>a{
        font-size: 16px;
    }
}


