@import url('https://fonts.googleapis.com/css?family=Montserrat');


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.m-b-15{
  margin-bottom: 15px;
}

.p-l-50{
  padding-left:50px;
}

.t-center{
  text-align:center;
}

.h3-home{
  text-decoration: none !important;
  font-weight: unset;
}