.about{
    background: url("./Images/olijf2-trans.png") no-repeat center;
    background-size: contain;
}

.about-image{
    width: 100%;
}

.about-title, .about-text {
    margin-top: 30px;
    margin-bottom: 50px;
}

.about-title{
    font-weight: unset;
    text-align: left;
    text-decoration: underline 2px;
}

.about-text{
    font-size: 16px;
}

.about-button{
    justify-content: space-around;
    background-color: #97aa8f;
    width: 40%;
    padding: 10px;
    display: flex;
    font-size: 1.8vw;
    font-family: hello paris;
    color: #FFF;
    text-decoration: none;
    border: #997330 solid 3px;
    border-radius: 5px;
}

.about-button:hover{
    color: #997330;
    box-shadow: 5px 5px #455621;
    font-weight: bold;
}

@media (max-width: 1000px) {
    .about-button{
        width: 60%;
        font-size: 3.5vw;
    }
    .about{
        align-items: center;
        display: flex;
        flex-direction: column;
    }
}

